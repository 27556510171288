import React from "react";
import styled from "styled-components";

const StyledLogoNeumafit = styled.svg`
  &.logo-neumafit {
    fill: none;
    height: 48;
    viewbox: 0 0 120 48;
    width: 120;
  }

  & .path {
    fill: white;
  }

  cursor: pointer;

  &:hover {
    .path {
      fill: #a7a7a7;
    }
  }
`;

export const LogoNeumafit = ({ className, onClick }) => {
  return (
    <div onClick={onClick}>
      <StyledLogoNeumafit className={`logo-neumafit ${className}`}>
        <path
          className="path"
          d="M6.1499 20.3213C2.76141 20.3213 0 23.1631 0 26.6574C0 28.7695 0 30.8816 0 32.9936C0.667341 32.9936 1.32893 32.9936 1.99627 32.9936C1.99627 30.8816 1.99627 28.7695 1.99627 26.6574C1.99627 24.2962 3.85447 22.374 6.14415 22.374C8.43382 22.374 10.2977 24.2962 10.2977 26.6574C10.2977 28.7695 10.2977 30.8816 10.2977 32.9936C10.9651 32.9936 11.6267 32.9936 12.294 32.9936C12.294 30.8816 12.294 28.7695 12.294 26.6574C12.294 23.1631 9.5384 20.3213 6.1499 20.3213Z"
        />
        <path
          className="path"
          d="M88.8771 21.348C88.8771 25.228 88.8771 29.1139 88.8771 32.9939C89.5447 32.9939 90.2065 32.9939 90.8734 32.9939C90.8734 31.226 90.8734 29.4521 90.8734 27.6841C92.5876 27.6841 94.3084 27.6841 96.0225 27.6841C96.0225 26.9959 96.0225 26.3137 96.0225 25.6255C94.3084 25.6255 92.5876 25.6255 90.8734 25.6255C90.8734 24.1957 90.8734 22.7719 90.8734 21.3421C90.8734 18.9809 92.7316 17.0587 95.0211 17.0587C95.3549 17.0587 95.6887 17.0587 96.0225 17.0587C96.0225 16.3705 96.0225 15.6882 96.0225 15C95.6887 15 95.3549 15 95.0211 15C91.6327 15.0119 88.8771 17.8536 88.8771 21.348Z"
        />
        <path
          className="path"
          d="M78.2342 20.3213C74.8458 20.3213 72.0842 23.1631 72.0842 26.6574C72.0842 30.1518 74.84 32.9936 78.2342 32.9936C78.3891 32.9936 78.5389 32.9936 78.6946 32.9936C78.6946 32.3054 78.6946 31.6232 78.6946 30.935C78.5389 30.935 78.3891 30.935 78.2342 30.935C75.9447 30.935 74.0807 29.0127 74.0807 26.6515C74.0807 24.2903 75.9447 22.3681 78.2342 22.3681C80.5236 22.3681 82.3818 24.2903 82.3818 26.6515C82.3818 28.7636 82.3818 30.8756 82.3818 32.9876C83.0495 32.9876 83.7106 32.9876 84.3782 32.9876C84.3782 30.8756 84.3782 28.7636 84.3782 26.6515C84.384 23.1631 81.6284 20.3213 78.2342 20.3213Z"
        />
        <path
          className="path"
          d="M62.787 20.3213C60.7045 20.3213 58.76 21.4426 57.6382 23.2283C56.5106 21.4366 54.5776 20.3213 52.4893 20.3213C49.1008 20.3213 46.3452 23.1631 46.3452 26.6574C46.3452 28.7695 46.3452 30.8816 46.3452 32.9936C47.0125 32.9936 47.6741 32.9936 48.3414 32.9936C48.3414 30.8816 48.3414 28.7695 48.3414 26.6574C48.3414 24.2962 50.1996 22.374 52.4893 22.374C54.779 22.374 56.6372 24.2962 56.6372 26.6574C56.6372 28.7695 56.6372 30.8816 56.6372 32.9936C57.3045 32.9936 57.9661 32.9936 58.6334 32.9936C58.6334 30.8816 58.6334 28.7695 58.6334 26.6574C58.6334 24.2962 60.4974 22.374 62.787 22.374C65.0767 22.374 66.935 24.2962 66.935 26.6574C66.935 28.7695 66.935 30.8816 66.935 32.9936C67.6023 32.9936 68.2638 32.9936 68.9312 32.9936C68.9312 30.8816 68.9312 28.7695 68.9312 26.6574C68.9369 23.1631 66.1813 20.3213 62.787 20.3213Z"
        />
        <path
          className="path"
          d="M41.1912 26.6574C41.1912 29.0187 39.3272 30.9409 37.0433 30.9409C34.7536 30.9409 32.8955 29.0187 32.8955 26.6574C32.8955 24.7293 32.8955 22.8012 32.8955 20.873C32.2281 20.873 31.5665 20.873 30.8992 20.873C30.8992 22.8012 30.8992 24.7293 30.8992 26.6574C30.8992 30.1518 33.6548 32.9936 37.0433 32.9936C40.4318 32.9936 43.1875 30.1518 43.1875 26.6574C43.1875 24.7293 43.1875 22.8012 43.1875 20.873C42.5201 20.873 41.8585 20.873 41.1912 20.873C41.1912 22.8012 41.1912 24.7293 41.1912 26.6574Z"
        />
        <path
          className="path"
          d="M27.7406 26.6574C27.7406 23.1631 24.9849 20.3213 21.5965 20.3213C18.208 20.3213 15.4466 23.1631 15.4466 26.6574C15.4466 30.1518 18.2022 32.9936 21.5965 32.9936C23.4662 32.9936 25.3359 32.9936 27.2056 32.9936C27.2056 32.3054 27.2056 31.6232 27.2056 30.935C25.3359 30.935 23.4662 30.935 21.5965 30.935C19.6635 30.935 18.0124 29.5704 17.5637 27.6838C20.9579 27.6838 24.3464 27.6838 27.7406 27.6838C27.7406 27.3456 27.7406 27.0015 27.7406 26.6574ZM17.5637 25.6311C18.0124 23.7445 19.6635 22.3799 21.5965 22.3799C23.5237 22.3799 25.1806 23.7445 25.6293 25.6311C22.9369 25.6311 20.2503 25.6311 17.5637 25.6311Z"
        />
        <path
          className="path"
          d="M102.518 20.3154H100.521V32.9936H102.518V20.3154Z"
        />
        <path
          className="path"
          d="M114.156 25.6311V20.3213H112.159V25.6311H107.01V27.6838H112.159V32.9995H114.156V27.6838H119.304V25.6311H114.156Z"
        />
      </StyledLogoNeumafit>
    </div>
  );
};
