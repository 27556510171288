import PropTypes from "prop-types";
import React from "react";
// import { Facebook } from "./Facebook";
import styled from "styled-components";
const StyledFooter = styled.div`
  background-color: #111111;
  height: 358px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
  & .frame {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 42px;
    left: 90px;
    position: relative;
    top: 61px;
    width: calc(100% - 90px);
  }
  & .div {

    height: 145px;

  }
  & .menu {
    height: 145px;

    position: absolute;
    top: 0;
    width: 383px;
  }
  & .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    gap: 44px;
    left: 1px;
    position: relative;
  }
  & .menu-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
  & .text-wrapper {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: -1px;
    position: relative;
    width: 98px;
  }
  & .menu-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    opacity: 0.7;
    position: relative;
    width: 100%;
  }
  & .text-wrapper-2 {
    align-self: stretch;
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    margin-top: -1px;
    opacity: 0.8;
    position: relative;
  }
  & .text-wrapper-3 {
    align-self: stretch;
    color: #ffffff;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 21px;
    opacity: 0.8;
    position: relative;
  }
  & .component-facebook {
    height: 24px;
    left: 0;
    position: absolute;
    top: 58px;
    color: #ffffff;
    width: 24px;
  }
  & .huge-icon-social {
    height: 22px;
    left: 30px;
    position: absolute;
    top: 59px;
    color: #ffffff;
    width: 22px;
  }
  & .logo-neumafit {
    height: 34px;
    left: 0;
    position: absolute;
    top: 0;
    width: 242px;
  }
  & .frame-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 48px;
    position: relative;
    width: 100%;
  }
  & .frame-3 {
    align-items: flex-end;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
  }
  & .vector {
    height: 1px;
    margin-left: -1px;
    margin-top: -0.5px;
    object-fit: cover;
    position: relative;
    width: 100%;
  }
  & .frame-4 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 319px;
    position: relative;
    width: 100%;
  }
  & .springfield {
    color: #fefefeb2;
    flex: 1;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
  }
  & .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15px;
    margin-right: 200px;
    position: relative;
  }
  & .text-wrapper-4 {
    color: #fefefeb2;
    font-family: "Montserrat-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
`;
export const Footer = ({
  hugeIconSocial = "img/instagram.svg",
  logoNeumafit = "img/logo-neumafit-1.svg",
  vector = "img/vector-21.svg",
}) => {
  return (
    <StyledFooter>
      {" "}
      <div className="frame">
        {" "}
        <div className="div">
          {" "}
          <div className="menu"> </div>{" "}
          <img
            className="component-facebook"
            alt="Huge icon social"
            src="img/facebook.svg"
          />{" "}
          <img
            className="huge-icon-social"
            alt="Huge icon social"
            src={hugeIconSocial}
          />{" "}
          <img
            className="logo-neumafit"
            alt="Logo neumafit"
            src={logoNeumafit}
          />{" "}
        </div>{" "}
        <div className="frame-wrapper">
          {" "}
          <div className="frame-3">
            {" "}
            <img className="vector" alt="Vector" src={vector} />{" "}
            <div className="frame-4">
              {" "}
              <div className="springfield">
                Seoul&nbsp;&nbsp;Gwanak-gu&nbsp;&nbsp;Gwanak-ro
                1&nbsp;&nbsp;Bldg. 35&nbsp;&nbsp;Unit 433-2
              </div>{" "}
              <div className="frame-5">
                {" "}
                <div className="text-wrapper-4">neumafit</div>{" "}
                <div className="text-wrapper-4">© 2024</div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </StyledFooter>
  );
};
Footer.propTypes = {
  hugeIconSocial: PropTypes.string,
  logoNeumafit: PropTypes.string,
  vector: PropTypes.string,
};
