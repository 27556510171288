import styled from "styled-components";


const StyledBox = styled.div`
  display: flex; /* Flex 컨테이너로 설정 */
  align-items: top; /* 자식 요소들을 위쪽으로 정렬 */
  justify-content: center; /* 자식 요소들을 가로 중앙에 위치시킴 */
  width: 100%; /* 부모 컨테이너의 전체 너비를 차지 */
  padding: 20px 0; /* 상하 패딩 추가 */
`;

const ImageTextWrapper = styled.div`
  display: flex; /* Flex 컨테이너로 설정 */
  flex-direction: column; /* 요소들을 수직으로 정렬 */
  align-items: center; /* 가로 중앙 정렬 */
  margin: 0 10px; /* 좌우 간격 조정 */
  width: calc(50% - 160px);
  justify-content: flex-start;
`;

const TextWrapper = styled.p`
  color: #111111;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22.5px;
  text-align: left; /* 텍스트 중앙 정렬 */
  margin-top: 20px; /* 이미지와 텍스트 사이의 마진 */
`;

const DivText = styled.div`
  color: #111111;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26.4px;

  text-align: left; /* 제목 중앙 정렬 */
  margin-top: 20px; /* 이미지와 제목 사이의 마진 */
`;

const Image = styled.img`
  height: 400px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 20px;
`;

export const SpecBox = () => {
  return (
    <StyledBox>
      <ImageTextWrapper>
        <Image className="image" alt="Image" src="img/image-09-1.gif" />
        <DivText>Professional-grade Accuracy</DivText>
        <TextWrapper>
          Performing high-precision assessments of physical abilities is crucial
          in sports and medicine.
        </TextWrapper>
      </ImageTextWrapper>
      <ImageTextWrapper>
        <Image alt="Rectangle" src="img/rectangle-3464993.png" />
        <TextWrapper>
          PACER analyzes the user's key physiological data in real-time through
          built-in sensors such as flow sensors, photoplethysmography (PPG)
          sensors, and Inertial Measurement Unit (IMU) sensors. Leveraging over
          20 types of data, PACER's algorithm deeply analyzes aerobic capacity.
        </TextWrapper>
      </ImageTextWrapper>
    </StyledBox>
  );
};
