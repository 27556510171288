import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RiArrowRightUpLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
const StyledAboutFrame = styled.div`
  margin-top: 100px;
  height: 448px;
  overflow: hidden;
  overflow-x: scroll;
  width: 100vw%;
  & .about {
    height: 448px;
    position: relative;
    width: 2668px;
  }
  & .pacerdevice {
    background-image: img/mask-image-1.png;
    background-position: 50% 50%;
    background-size: cover;
    height: 448px;
    left: 72px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 812px;
  }
  & .overlap-group {
    background-color: #11111133;
    height: 448px;
    position: relative;
    width: 815px;
  }
  & .text-wrapper {
    color: #ffffff;
    font-family: "obviously-variable", Helvetica;
    font-variation-settings: "wght" 650, "wdth" 200;
    font-size: 48px;
    left: 36px;
    letter-spacing: 0;
    line-height: 52.8px;
    position: absolute;
    top: 295px;
    white-space: nowrap;
  }
  & .div {
    color: #ffffffcc;
    font-family: "Montserrat", Helvetica;
    font-size: 15px;
    font-weight: 500;
    left: 36px;
    letter-spacing: 0;
    line-height: 22.5px;
    position: absolute;
    top: 364px;
    width: 625px;
  }
  & .component {
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    display: inline-flex;
    gap: 4px;
    height: 48px;
    justify-content: center;
    left: 36px;
    padding: 0px 40px;
    position: absolute;
    top: 38px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
      transition: 0.5s;
      scale: 1.02;
    }
  }
  & .button {
    all: unset;
    box-sizing: border-box;
    color: #111111;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  & .theapp {
    height: 448px;
    left: 928px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 812px;
  }
  & .overlap {
    height: 514px;
    position: relative;
    width: 815px;
  }
  & .rectangle {
    background-color: #11111180;
    height: 448px;
    left: 0;
    position: absolute;
    top: 0;
    width: 815px;
  }
  & .img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 125%;
    object-fit: cover;
    filter: brightness(60%);
  }
  & .mockup-iphone {
    height: 400px;
    left: 435px;
    position: absolute;
    top: 114px;
    width: 195px;
  }
  & .overlap-group-2 {
    height: 400px;
    position: relative;
  }
  & .template-iphone-13 {
    height: 400px !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 195px !important;
  }
  & .template-iphone-instance {
    height: 409px !important;
    left: -166px !important;
    top: -75px !important;
    width: 243px !important;
  }
  & .screen {
    height: 450px;
    left: -150px;
    position: absolute;
    top: -50px;
  }
  & .screen1-1 {
    height: 400px;
    left: -0px;
    position: absolute;
    top: -55px;
  }
  & .mockup-iphone-2 {
    height: 400px;
    left: 587px;
    position: absolute;
    top: 24px;
    width: 195px;
  }
  & .template-iphone-13-instance {
    height: 448px !important;
    left: -166px !important;
    top: -24px !important;
    width: 91px !important;
  }
  & .screen-2 {
    height: 381px;
    left: -13781px;
    position: absolute;
    top: 16862px;
    width: 176px;
  }
  & .p {
    color: #ffffffcc;
    font-family: "Montserrat", Helvetica;
    font-size: 15px;
    font-weight: 500;
    left: 36px;
    letter-spacing: 0;
    line-height: 22.5px;
    position: absolute;
    top: 364px;
    width: 379px;
  }
  & .technology {
    background-image: url(./technology.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 448px;
    left: 1784px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 812px;
  }
  & .overlap-2 {
    background-color: #1111114c;
    height: 448px;
    position: relative;
    width: 815px;
  }
`;
export const AboutFrame = ({
  className,
  pacerdeviceClassName,
  rectangle = "img/rectangle-3464982.svg",
  templateIphoneBaseIphone = "img/base-iphone-13-3.svg",
  screen = "img/iphone23.png",
  templateIphoneImg = "img/image.svg",
  screenClassName,
  img = "img/image.png",
  technologyClassName,
}) => {
  const navigate = useNavigate();
  const [scrollX, setScrollX] = useState(0);
  const containerRef = useRef(null);
  console.log("scrollX:", scrollX);
  useEffect(() => {
    const handleScroll = () => {
      const position = containerRef.current.scrollLeft;
      setScrollX(position); // 스크롤 위치를 vh 단위로 변환
      console.log("position:", position);
    };

    containerRef.current.addEventListener("scroll", handleScroll);
    const cur= containerRef.current
    return () => {
      cur.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  return (
    <StyledAboutFrame className={`about-frame ${className}`} ref={containerRef}>
      {" "}
      <div className="about">
        {" "}
        <div className={`pacerdevice ${pacerdeviceClassName}`}>
          {" "}
          <div className="overlap-group">
            {" "}
            <img
              className="img"
              alt="Rectangle"
              src="img/mask-image-1.png"
              style={{
                transform: `translateX(${scrollX * 0.1}px)`,
                // transition: "all 0.5s",
              }}
            />{" "}
            <div
              className="component"
              onClick={() => {
                // route to about pacer
                navigate("/about-pacer");
              }}
            >
              {" "}
              <button className="button">About Pacer</button>
              <RiArrowRightUpLine />
            </div>{" "}
            <div className="text-wrapper">PACER DEVICE</div>{" "}
            <p className="div">
              {" "}
              The PACER revolutionized CPET allowing you the freedom to conduct
              tests wherever you are. Get professional-level testing with the
              PACER mask!{" "}
            </p>{" "}
          </div>{" "}
        </div>{" "}
        <div className="theapp">
          {" "}
          <div className="overlap">
            {" "}
            <div className="rectangle" />{" "}
            <img
              className="img"
              alt="Rectangle"
              src={rectangle}
              style={{
                transform: `translateX(${scrollX * 0.1-150}px)`,
                // transition: "all 0.5s",
              }}
            />{" "}
            <div className="mockup-iphone">
              {" "}
              <div className="overlap-group-2">
                <img
                  className="screen"
                  alt="Screen"
                  src={screen}
                  style={{
                    transform: `translateX(${150 - scrollX * 0.15}px)`,
                    // transition: "all 0.5s",
                  }}
                />{" "}
                <img
                  className="screen1-1"
                  alt="Screen"
                  src="img/iphone24.png"
                  style={{
                    transform: `translateX(${300 - scrollX * 0.3}px)`,
                    // transition: "all 0.5s",
                  }}
                />{" "}
              </div>{" "}
            </div>{" "}
            <div className="mockup-iphone-2">
              {" "}
              <img
                className={`screen-2 ${screenClassName}`}
                alt="Screen"
                src={img}
              />{" "}
            </div>{" "}
            <div className="text-wrapper">THE APP</div>{" "}
            <p className="p">
              {" "}
              The PACER app enables you to unlock the best training approach
              tailored to your unique needs.{" "}
            </p>{" "}
            <div
              className="component"
              onClick={() => {
                // route to about pacer
                navigate("/about-app");
              }}
            >
              {" "}
              <button className="button">About The App</button>{" "}
              <RiArrowRightUpLine />
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className={`technology ${technologyClassName}`}>
          {" "}
          <div className="overlap-2">
            {" "}
            <img
              className="img"
              alt="Rectangle"
              src="img/tech-image.jpeg"
              style={{
                transform: `translateX(${scrollX * 0.1-300}px)`,
                // transition: "all 0.5s",
              }}
            />{" "}
            <div
              className="component"
              onClick={() => {
                // route to about pacer
                navigate("/technology");
              }}
            >
              {" "}
              <button className="button">About Technology</button>
              <RiArrowRightUpLine />
            </div>{" "}
            <div className="text-wrapper">TECHNOLOGY</div>{" "}
            <p className="div">
              {" "}
              The PACER sets the standard for precision with its
              professional-grade accuracy, surpassing conventional analysis
              wearables.{" "}
            </p>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </StyledAboutFrame>
  );
};
AboutFrame.propTypes = {
  rectangle: PropTypes.string,
  templateIphoneBaseIphone: PropTypes.string,
  screen: PropTypes.string,
  templateIphoneImg: PropTypes.string,
  img: PropTypes.string,
};
