import { useEffect, useState } from "react";
import styled from "styled-components";

const PicturesPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250vh;
`;

const FirstImage = styled.img`
  top: 20vh;
  left: 14vw;

  position: absolute;
  height: auto;
`;

const SecondImage = styled.img`
  top: 120vh;
  right: 15vw;

  position: absolute;
  height: auto;
`;

const PopupImageBox = styled.div`
  top: 60vh;
  left: 15vw;
  width: 85vw;
  position: absolute;
  height: auto;
  transition: all 0.4s cubic-bezier(0.295, 0.755, 0.35, 1.125);
  transform: translateY(0);
  overflow: hidden;
`;

const PopupImage = styled.img`
  filter: brightness(80%) contrast(130%);
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 25%,
    rgba(0, 0, 0, 0) 67%
  );
  width: 105vw;
`;

const PopupText = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  height: 72px;
  left: 12%;
  letter-spacing: 0;
  line-height: 24px;
  position: fixed;
  text-align: center;
  top: 25%;
`;
const StyledVideo = styled.video`
  position: absolute;
  width: 330px;
  height: 450px;
  left: 10vw;
  top: 170vh;
  object-fit: cover;
`;

const DescWrapper = styled.div`
  position: absolute;
  top: 210vh;
  right: 10vw;
`;

const PictureDesc = styled.div`
  color: #ffffff;
  font-family: "obviously-variable", sans-serif;
  font-variation-settings: "wght" 700, "wdth" 300;
  font-size: 52px;
  height: 57px;
  letter-spacing: 0;
  line-height: 1;
  text-align: right;
  white-space: nowrap;
`;

const PictureSubDesc = styled.div`
  color: #ffffff;
  font-family: "obviously-variable", sans-serif;
  font-variation-settings: "wght" 200, "wdth" 400;
  font-size: 15px;
  left: 0;
  letter-spacing: 0;
  line-height: 1;
  text-align: right;
`;
export const Pictures = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      const viewportHeight = window.innerHeight;
      setScrollY((100 * position) / viewportHeight); // 스크롤 위치를 vh 단위로 변환
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <PicturesPage>
      <FirstImage src="img/image-18-2.png" alt="First Image" />

      <PopupImageBox
        style={{
          transform: `translateY(${
            scrollY > 87 ? (scrollY - 87) * 0.07 : 50
          }vh)`,
          opacity: `${scrollY > 87 ? 1 : 0.5}`,
        }}
      >
        <PopupImage src="img/man.png" alt="Popup Image" />
        <PopupText>
          WE CAN'T IMPROVE <br /> WHAT WE DON'T MEASURE
       
        </PopupText>
      </PopupImageBox>

      <SecondImage src="img/image-22-2.png" alt="First Image" />
      <StyledVideo autoPlay muted loop>
        <source src="img/smallt.mp4" type="video/mp4" />
      </StyledVideo>
      <DescWrapper>
        <PictureDesc>PACER *</PictureDesc>
        <PictureSubDesc>
          * World’s Smartest Wearable Breath Analyzer <br /> Gold Standard
          Analysis CPET <br /> neumafit PACER - the game changer <br /> 
        </PictureSubDesc>
      </DescWrapper>
    </PicturesPage>
  );
};
