import styled from "styled-components";
import { Instructions } from "./instruction";
import { ProsBox } from "./pros";
import { SpecBox } from "./spec";
import { Navbar } from "../navbar/screens/Navbar/Navbar";
import { Footer } from "../footer/footer";
import { useEffect } from "react";
const SlideContainer = styled.div`
  width: 100%;
  overflow: scroll;
`;

const HeroWrapper = styled.div`
  width: 100%;
  padding-top: 90px;
  margin: 0 150px;
  margin-bottom: 20px;
`;
export const Gear = () => {
  useEffect(() => {
    window.scrollTo(0, 0);  // 페이지가 로드될 때 스크롤을 맨 위로 이동
  }, []);  
  return (
    <>
      <Navbar></Navbar>
      <HeroWrapper>
        <img
          src="img/20240614-jiyounkim-studio-mask-image0428-1.png"
          alt="hero"
        />
      </HeroWrapper>
      <SlideContainer>
        <Instructions />
      </SlideContainer>
      <SpecBox />
      <ProsBox />
      <Footer />
    </>
  );
};
