import React from "react";
import { Menu } from "../../components/Menu";
import { LogoNeumafit } from "../../icons/LogoNeumafit/LogoNeumafit";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MdShoppingCart } from "react-icons/md";

const StyledNavbar = styled.div`
  height: ${(props) => (props.height ? props.height : "90px")};
  width: 100%;
  position: fixed;
  transition: 0.4s;
  z-index: 1000;
  background-color: #111111cc;
    @media (max-width: 768px) {
    display: none; // 768px 이하에서는 숨김
  }

  & .navbar-white-default {
    align-items: flex-start;
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding: 20px 32px;
    position: relative;
  }

  & .logo-neumafit {
    height: 48px !important;
    position: relative !important;
    width: 120px !important;
  }

  & .action-button {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
  }

  & .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 30px;
    height: 48px;
    position: relative;
    cursor: pointer;
    &:hover {
      color: #f1f1f1;
      transition: 0.2s;
      scale: 1.02;
    }
  }

  & .menu-instance {
    flex: 0 0 auto !important;
  }

  & .hosting-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    height: 20px;
    justify-content: center;
    position: relative;
  }

  & .text-wrapper {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    line-height: 17px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .bt-shop {
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 48px;
    justify-content: center;
    padding: 0px 40px;
    position: relative;
  }

  & .shop-now {
    color: #111111;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.6px;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  display: ${(props) => (props.open ? "flex" : "none")};
  height: ${(props) => (props.open ? "80px" : "0px")};
  transition: 0.4s;
  flex-direction: column;
  background-color: #11111100;
  padding: 20px;
  top: 15px;
  left: -20px;
  width: 80px;
  z-index: 1000;
  & .menu {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  & .menu:hover {
    color: #ffffff;
    transform: scale(1.05);
    transition: 0.2s;
  }
`;

export const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOnHover = () => {
    setOpen(true);
  };
  const handleOnLeave = () => {
    setOpen(false);
  };
  return (
    <StyledNavbar height={ open ? "130px" : "90px"}>
      <div className="navbar-white-default">
        <LogoNeumafit
          className="logo-neumafit"
          onClick={() => {
            navigate("/");
          }}
        />
        <div className="action-button">
          <div
            className="div"
            onMouseEnter={handleOnHover}
            onMouseLeave={handleOnLeave}
          >
            <Menu
              className="menu-instance"
              property1="product"
              property2="default"
              property3="white"
            ></Menu>

            <Dropdown open={open}>
              <div
                className="menu"
                onClick={() => {
                  navigate("/about-pacer");
                }}
              >
                PACER
              </div>
              <div
                className="menu"
                onClick={() => {
                  navigate("/about-app");
                }}
              >
                THE APP
              </div>
            </Dropdown>
          </div>
          <div className="div" onClick={() => {
                  navigate("/technology");
                }}>
            <div className="hosting-wrapper">
              <div className="text-wrapper">TECHNOLOGY</div>
            </div>
          </div>
          <div className="bt-shop">
            <div className="shop-now">
              <MdShoppingCart
                size={18}
                style={{
                  marginBottom: "-3px",
                  marginRight: "2px",
                }}
              />
              Comming Soon
            </div>
          </div>
        </div>
      </div>
    </StyledNavbar>
  );
};
