import { Footer } from "../footer/footer";
import { Navbar } from "../navbar/screens/Navbar/Navbar";

export const Tech = () => {
  return (
    <>
      <Navbar />
      <img
      src="img/tech-group2.png"
        style={{
          width: "100%",
          paddingTop: "90px",
        }}
      >

      </img>
      <Footer />
    </>
  );
};
