import styled from "styled-components";
import { AboutFrame } from "./aboutFrame";

const LearnMoreFrame = styled.div`
  position: relative;
  background-color: white;
  width: 100%;
  padding-bottom: 100px;
`;

const Title = styled.div`

  color: #111111;
  font-family: "obviously-variable", Helvetica;
  font-variation-settings: "wght" 700, "wdth" 210;
  font-size: 74px;
  height: 81px;

  letter-spacing: 0;
  line-height: 1;
  text-align: center;

  white-space: nowrap;
  margin-top: 100px;
  padding-top: 100px;
`;

const Desc = styled.div`
  color: #111111;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  text-align: center;
`;

export const LearnMore = () => {
  return (
    <LearnMoreFrame>
      <Title>LEARN MORE ABOUT PACER</Title>
      <Desc>
        * Revolutionizing breath metric measurement with the world's premier wearable technology, setting new standards for respiratory health monitoring
        Analyzer
      </Desc>
      <AboutFrame />
    </LearnMoreFrame>
  );
};
