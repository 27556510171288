import { Main } from "./main/main";

import { Gear } from "./gear/gear";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppPage from "./app/app";
import { Tech } from "./tech/Tech";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about-pacer" element={<Gear />} />
        <Route path="/about-app" element={<AppPage />} />
        <Route path="/technology" element={<Tech />} />
      </Routes>
    </Router>
  );
}

export default App;
