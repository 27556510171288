import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MdShoppingCart } from "react-icons/md";
const StyledLabel = styled.div`
  position: absolute;
  height: 22vh; // 뷰포트 높이에 대한 비율로 크기 조정
  width: 65vw; // 뷰포트 너비에 대한 비율로 크기 조정
  top: 50vh; // 뷰포트 높이에 대한 비율로 위치 조정
  left: 10vw; // 뷰포트 너비에 대한 비율로 위치 조정
  & .text-wrapper {
    color: #ffffff;
    font-family: "obviously-variable", sans-serif;
    font-variation-settings: "wght" 700, "wdth" 310;
    font-size: 13.6vw; // 뷰포트 너비에 따라 글꼴 크기 조정
    line-height: 1; // 글꼴 크기에 따라 조정된 라인 높이
    white-space: nowrap;
  }
  & .subtext-wrapper {
    color: #ffffff;
    font-family: "obviously-variable", sans-serif;
    font-variation-settings: "wght" 300, "wdth" 310;
    font-size: 1.6vw;
    letter-spacing: 0;
    line-height: 1;
    white-space: nowrap;
  }
`;

const BtnWrapper = styled.div`
  @media (max-width: 768px) {
    display: none; // 768px 이하에서는 숨김
  }
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  display: inline-flex;
  gap: 4px;
  height: 48px;
  justify-content: center;
  padding: 0px 40px;
  position: relative;
  margin-top: 60px;
`;

const StyledBtShop = styled.div`

  color: #111111;
  font-family: "Montserrat", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  line-height: normal;
  position: relative;
  width: fit-content;
  align-items: baseline; 
`;

const StyledApp = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px; // 768px 이하에서는 위쪽 마진 추가
    margin-left: 0px;
  }
  margin-left: 20px;
  align-items: center;
  background-color: #ffffff03;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 12px;
  display: inline-flex;
  gap: 4px;
  height: 48px;
  justify-content: center;
  padding: 0px 40px;
  position: relative;
  color: #ffffff;
  & .button {
    all: unset;
    box-sizing: border-box;
    
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  &:hover {
    background-color: #f1f1f122;
    transition: 0.2s;
    scale: 1.02;
  }
  cursor: pointer;
`;
export const PacerLabel = () => {
  const navigate = useNavigate();
  return (
    <StyledLabel>
      <div className="text-wrapper">PACER *</div>
      <div className="subtext-wrapper">
        ; access a professional-level evaluation of your aerobic fitness
      </div>
      <BtnWrapper>
        <StyledBtShop><MdShoppingCart size={18} style={{
          marginBottom: '-3px'
        }} />Comming Soon</StyledBtShop>
      </BtnWrapper>
      <StyledApp onClick={
        ()=>{
          navigate('/about-app');
        }
      }>
        <button className="button">Install App</button>
      </StyledApp>
    </StyledLabel>
  );
};
