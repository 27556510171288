import styled from "styled-components";

import Hero from "./hero/hero";
import {Navbar} from "../navbar/screens/Navbar/Navbar";
import {Pictures} from "./pictures/pictures";
import { LearnMore } from "./learnMore/learnMore";
import { Footer } from "../footer/footer";
import { useEffect, useState } from "react";
//검정색 100% width
const BackgroundWrapper = styled.div`
  position: relative;
  background-color: black;
  width: 100%;
`;

export function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);  // 페이지가 로드될 때 스크롤을 맨 위로 이동
  }, []);  
  const [isVisible, setIsVisible] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isVisible ? (
   <BackgroundWrapper>
    <Navbar />
    <Hero/>
    <Pictures/>
    <LearnMore/>
    <Footer/>
   </BackgroundWrapper>
  ): (
    <BackgroundWrapper>
    <Hero/>
   </BackgroundWrapper>
  )
}