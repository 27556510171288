import styled from "styled-components";
import { PacerLabel } from "./pacerLabel";
import { HeroDesc } from "./heroDesc";

const HeroWithVideo = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const StyledVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

const DesktopForMore = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
  position: absolute;
  color: #ffffff;
  font-size: 0.9em;
  bottom: 15vh;
  text-align: center;
  margin: 0 10vw;
  padding: 15px;
  border-radius: 12px;
  background-color: #00000050;
`;

export default function Hero() {
  return (
    <>
      <HeroWithVideo>
        <StyledVideo autoPlay muted loop>
          <source src="img/testrun.mp4" type="video/mp4" />
        </StyledVideo>
        <PacerLabel />
        <HeroDesc />
      </HeroWithVideo>
      <DesktopForMore>
        Please visit our website on a desktop for more information.
      </DesktopForMore>
    </>
  );
}
