import styled from "styled-components";
import { Footer } from "../footer/footer";
import { Navbar } from "../navbar/screens/Navbar/Navbar";
import { useEffect } from "react";

const Container = styled.div`
  width: 100%;
  padding-top: 90px;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const ButtonImage = styled.img`
  height: 2.7vw;
  width: auto;
  position: absolute;
  top: calc(40vw + 90px);
  left: ${({ left }) => left};
`;

export default function AppPage() {
  useEffect(() => {
    window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 맨 위로 이동
  }, []);
  return (
    <>
      <Navbar />
      <Container>
        <Image src="img/desktop/theapp/app-header.png" alt="app" />
        <ButtonImage
          src="img/desktop/theapp/apple.png"
          alt="button"
          left={"10vw"}
        />
        <ButtonImage
          src="img/desktop/theapp/google.png"
          alt="button"
          left={"20vw"}
        />
        <ButtonImage
          src="img/desktop/theapp/amazon.png"
          alt="button"
          left={"30vw"}
        />
        <Image src="img/desktop/theapp/app-body.png" alt="app" />
        <Footer />
      </Container>
    </>
  );
}
