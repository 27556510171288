import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const StyledMenu = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 20px;
  justify-content: center;
  position: relative;

  & .hosting {
    color: #ffffff;
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.6px;
    line-height: 17px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .expand-more {
    height: 20px;
    position: relative;
    width: 20px;
  }
`;

export const Menu = ({ property1, property2, property3, className, expandMore = "/img/expand-more-1.png" }) => {
  return (
    <StyledMenu className={`menu ${className}`}>
      <div className="hosting">
        {property1 === "product" && <>PRODUCT</>}

        {property1 === "technology" && <>TECHNOLOGY</>}
      </div>
      {property1 === "product" && <img className="expand-more" alt="Expand more" src={expandMore} />}
    </StyledMenu>
  );
};

Menu.propTypes = {
  property1: PropTypes.oneOf(["technology", "product"]),
  property2: PropTypes.oneOf(["default"]),
  property3: PropTypes.oneOf(["white"]),
  expandMore: PropTypes.string,
};
