import styled from "styled-components";

const Container = styled.div`
  width: calc(100vw - 300px); // 뷰포트 너비에서 마진 제외
  margin: 150px; // 전체 컨테이너에 마진 추가
  overflow: hidden; // 너비를 넘어가는 요소들을 숨김
`;
const FullWidthImage = styled.img`
  width: calc(100vw - 300px); // 뷰포트 너비를 100% 사용
  object-fit: cover; // 이미지가 컨테이너에 맞춰서 크기 조정되도록
`;
const Row = styled.div`
  display: flex;
  width: calc(100vw - 300px); // 전체 너비에서 마진 제외
  margin-bottom: 20px; // 행 사이에 마진 추가
  overflow: hidden; // 너비를 넘어가는 요소들을 숨김
`;

const Video = styled.video`
  width: ${({ large }) => (large ? "calc(60% - 10px)" : "calc(40% - 10px)")};
  height: 400px;
  object-fit: cover;
  margin-right: 20px; // 비디오와 이미지 사이에 간격 추가
`;

const Image = styled.img`
  width: ${({ large }) => (large ? "calc(60% - 10px)" : "calc(40% - 10px)")};
  height: 400px;
  object-fit: cover;
`;

const Image2 = styled.img`
  width: 100%;
  height: 400px;
  object-fit: cover;
`;

const ImageTextWrapper = styled.div`
  display: flex; /* Flex 컨테이너로 설정 */
  flex-direction: column; /* 요소들을 수직으로 정렬 */
  align-items: center; /* 가로 중앙 정렬 */

  width: calc(50% - 10px);
  margin-right: 20px; /* 좌우 간격 조정 */
`;

const TextWrapper = styled.p`
  color: #111111;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22.5px;
  text-align: left; /* 텍스트 중앙 정렬 */
  margin-top: 20px; /* 이미지와 텍스트 사이의 마진 */
`;

const DivText = styled.div`
  color: #111111;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1;
  line-height: 26.4px;

  text-align: left; /* 제목 중앙 정렬 */
  margin-top: 40px; /* 이미지와 제목 사이의 마진 */
`;

const Title = styled.div`
  color: #111111;
  font-family: "obviously-variable", Helvetica;
  font-variation-settings: "wght" 700, "wdth" 210;
  font-size: 40px;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
  width: 100%; // 전체 너비 사용
  margin-top: 70px; // 제목 위에 마진 추가
`;

const Desc = styled.div`
  color: #111111;
  font-family: "Montserrat", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.5px;
  text-align: center;
   width: 100%; // 전체 너비 사용
  margin-top: 30px; // 제목 위에 마진 추가
`;

export const ProsBox = () => {
  return (
    <>
      <Container>
        {/* 첫 번째 행: 비디오가 3, 사진이 2의 비율 */}
        <Row>
          <Video large src="img/whole.mp4" autoPlay muted loop />
          <Image src="img/1-76.png" />
        </Row>
        {/* 두 번째 행: 사진이 3, 비디오가 2의 비율 */}
        <Row>
          <Video src="img/whole2.mp4" autoPlay muted loop />
          <Image large src="img/7-1.png" />
        </Row>

        <Row>
          <ImageTextWrapper>
            <Image2 className="image" alt="Image" src="img/drawing_01.png" />
            <DivText>Fits Well, Feels Good</DivText>
            <TextWrapper>
              The PACER boasts universal wearability, ensuring comfort for users
              of all genders and races. Its ergonomic design guarantees a
              natural and comfortable fit, allowing you to focus solely on
              improving your running abilities.
            </TextWrapper>
            <DivText>Durability and Easy Maintenance</DivText>
            <TextWrapper>
              PACER is crafted from premium PP plastic and silicone, ensuring
              both durability and easy maintenance. Its waterproof and
              sweat-resistant materials make it highly resilient to intense
              workout conditions. Even when stained, it can be gently wiped with
              a damp cloth, ensuring it remains hygienic and ready to use for
              your next training session.
            </TextWrapper>
            <DivText>Optimal Performance and Comfort</DivText>
            <TextWrapper>
              Additionally, the lightweight material makes it more comfortable
              to wear
            </TextWrapper>
          </ImageTextWrapper>
          <ImageTextWrapper>
            <Image2 alt="Rectangle" src="img/drawing_02.png" />
            <TextWrapper>
              for extended periods, ensuring that you can train longer without
              any discomfort.
            </TextWrapper>
            <TextWrapper>
              PACER mask is meticulously engineered for optimal performance and
              comfort, featuring a soft silicone structure that conforms to your
              face for a secure and comfortable fit.
            </TextWrapper>
            <DivText>Easily Detachable Components</DivText>
            <TextWrapper>
              PACER is crafted from premium PP plastic and silicone, ensuring
              both durability and easy maintenance. Its waterproof and
              sweat-resistant materials make it highly resilient to intense
              workout conditions. Even when stained, it can be gently wiped with
              a damp cloth, ensuring it remains hygienic and ready to use for
              your next training session.
            </TextWrapper>
          </ImageTextWrapper>
        </Row>
        <FullWidthImage src="img/rectangle-3464989.png" />
        <Title>Maximize Results, Minimize Time & Expense with PACER</Title>
        <Desc>
          Traditional CPET centers offer precise diagnostics for exercise
          abilities. However, a single test can cost up to $230 and require
          appointment scheduling. Investing in PACER offers unlimited CPET tests
          at a comparable cost, revolutionizing your training and testing
          paradigm to enhance your fitness expertise.
        </Desc>
        <img src="img/table-group.png" alt="Rectangle" style={{
          width: "100%",
          height: "auto",
          marginTop: "60px",
        }} />
      </Container>
    </>
  );
};
