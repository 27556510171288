import React from "react";
import styled from "styled-components";
const StyledLabel = styled.div`
  height: 72px;
  width: 275px;
  & .world-s-smartest {
    color: #ffffff;
    font-family: "obviously-variable", Helvetica;
    font-size: 16px;
    font-style: italic;
    font-weight: 300;
    height: 72px;
    right: 5vw;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
    top: 83vh;
  }
`;
export const HeroDesc = () => {
  return (
    <StyledLabel>
      {" "}
      <p className="world-s-smartest">
        {" "}
        * World’s Smartest Wearable Breath Analyzer <br /> Gold Standard
        Analysis CPET <br /> neumafit PACER - the game changer{" "}
      </p>{" "}
    </StyledLabel>
  );
};
