import React from "react";
import styled from "styled-components";
const StyledFrame = styled.div`
  height: 316px;
  position: relative;
  width: 2575px;
  & .element-jiyounkim {
    height: 316px;
    left: 36px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 472px;
  }
  & .img {
    height: 316px;
    left: 544px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 472px;
  }
  & .image {
    height: 316px;
    left: 1052px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 472px;
  }
  & .element-jiyounkim-2 {
    height: 316px;
    left: 1560px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 472px;
  }
  & .element-jiyounkim-3 {
    height: 316px;
    left: 2068px;
    position: absolute;
    top: 0;
    width: 472px;
    object-fit: cover;
  }
`;
export const Instructions = () => {
  return (
    <StyledFrame>
      {" "}
      <img
        className="element-jiyounkim"
        alt="Element jiyounkim"
        src="img/20240614-jiyounkim-studio-mask-image0459.png"
      />{" "}
      <img
        className="img"
        alt="Element jiyounkim"
        src="img/20240614-jiyounkim-studio-mask-image0490.png"
      />{" "}
      <img className="image" alt="Image" src="img/image-154.png" />{" "}
      <img
        className="element-jiyounkim-2"
        alt="Element jiyounkim"
        src="img/20240614-jiyounkim-studio-mask-image0154-2.png"
      />{" "}
      <img
        className="element-jiyounkim-3"
        alt="Element jiyounkim"
        src="img/20240614-jiyounkim-studio-mask-image0286-4.jpeg"
      />{" "}
    </StyledFrame>
  );
};
